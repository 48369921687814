@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


body {
    background-color: white;
    font-family: 'Poppins', sans-serif;
  }
  *,
  ::before,
  ::after {
    border-width: 0;
  }
  